import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import LoginActions from '../../../../Redux/LoginRedux'
import UserRedux from '../../../../Redux/UserRedux'
import LoginInfo from './LoginInfo'
import Logout from './Logout'
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap'
import EllipsisText from 'react-ellipsis-text'

const propTypes = {
    children: PropTypes.node
}

const defaultProps = {}

class Header extends Component {
    logout() {
        localStorage.clear()
        this.props.logout()
    }

    componentDidMount() {
        if (this.props.user && this.props.user.locale) {
            this.props.i18n.changeLanguage(this.props.user.locale)
        } else {
            this.props.userRequest()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.fetchingUpate && nextProps.user?.username && this.props.fetchingUpate) {
            window.location.reload()
        }
        if (!nextProps.fetching && nextProps.user.locale && this.props.fetching) {
            this.props.i18n.changeLanguage(nextProps.user.locale)
        }
    }

    render() {
        // eslint-disable-next-line
        const { children, ...attributes } = this.props;
        const langs = {
            en: {
                code: 'en', icon: 'us', name: 'English'
            },
            ja: {
                code: 'ja', icon: 'jp', name: 'Japanese'
            }
        }
        const language = this.props.i18n.language
        return (
            <>
                <div className='header'>
                    <div className='top-header'>
                        <LoginInfo />
                        <Nav className='ml-auto mr-3' navbar>
                            <UncontrolledDropdown style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }} direction='down'>
                                {this.props.user && this.props.user.username
                                    ? <DropdownToggle nav onClick={() => this.logout()}>
                                        <strong> <EllipsisText text={'ログアウト'} length={50} /> </strong>
                                    </DropdownToggle> : <DropdownToggle nav> ログアウト </DropdownToggle>}
                            </UncontrolledDropdown>
                        </Nav>
                    </div>
                </div>
            </>
        )
    }
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        fetchingUpate: state.user.fetchingUpdate,
        fetching: state.user.fetching
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (username, password) => dispatch(LoginActions.logoutRequest()),
        userUpdateRequest: (params) => dispatch(UserRedux.userUpdate(params)),
        userRequest: (params) => dispatch(UserRedux.userRequest(params))
    }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(Header))
