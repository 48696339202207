import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react'
// sidebar nav config
import navigation from '../../_nav'
// routes config
import { routes } from '../../routes'
import DefaultAside from './DefaultAside'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import PrivateRoute from '../../../Navigation/PrivateRoute'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
class DefaultLayout extends Component {
  render() {
    return (
      <div className='app'>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className='app-body'>
          <AppSidebar display='lg'>
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className='main'>
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {
                  this.props.auth ? (
                    <>
                      {routes.map((route, idx) => {
                        return route.component ? (<PrivateRoute key={idx} {...route} />) : (null)
                      })}
                    </>
                  ) : (
                    <Redirect to='/admin/login' />
                  )
                }
                <Redirect from='/' to='/admin/dashboard' />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: (state.login.data && state.user.data) || (state.adminLogin.data && state.user.data)
    || (state.loginAvatar.data && state.user.data) || (state.loginStore.data && state.user.data)
})

export default translate('translations')(connect(mapStateToProps)(DefaultLayout))
