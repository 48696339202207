import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminLoginActions from '../../../../Redux/AdminLoginRedux'
import LoginAvatarActions from '../../../../Redux/LoginAvatarRedux'
import Utils from '../../Custom/Utils'
import { LOCAL_STORAGE } from '../../../../Utils/constants'
import AlertComponent from '../../../../Components/alert/Alert'
import { translate } from 'react-i18next'
import { Section, Image, Text, Label, Input, WrapperInput, Button } from './style'
import logo from '../../../assets/img/brand/gulliver.svg'
import Alert from 'react-s-alert'
import { createBrowserHistory } from "history";
import { withRouter } from 'react-router-dom'


class LoginAvatar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isAuthenticated: false,
      user: null,
      error: '',
    }
  }

  componentDidMount(){
    document.title = "ログイン｜アバターカスタムシステム"
  }

  UNSAFE_componentWillReceiveProps(props) {
    const path = createBrowserHistory().location.pathname
    const textError = path === '/store/login'
      ? <p style={{margin: 0}}>ログインに失敗しました。<br />IDまたはパスワードが異なります。</p> :
      <p style={{margin: 0}}>ログインに失敗しました。<br />IDまたはパスワードが異なります。</p>
    if (props.error) {

      this.setState({
        error: textError
      })
    }

    const role = localStorage.getItem(LOCAL_STORAGE.ROLE)
    if (props.data) {
      if (role === 'admin') {
        this.props.history.push('/admin')
      }
      if (role === 'store') {
        this.props.history.push('/avatar_management')
      }
      if (role === 'avatar') {
        this.props.history.replace('/add_info_avatar')
      }
    }
  }

  _verify() {
    const errorMessages = []
    const path = createBrowserHistory().location.pathname
    const textError = <p>ログインに失敗しました。<br />店舗IDまたはパスワードが異なります。</p>

    if (!this.state.username) {
      errorMessages.push(this.setState({
        error: textError
      }))
    }

    if (!this.state.password) {
      errorMessages.push(this.setState({
        error: textError
      }))
    }

    return errorMessages.length ? 0 : 1
  }

  _login = () => {
    if (!this._verify()) return
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    })
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this._login();
    }
  }

  onChange = (e) => {
    const { value, name } = e.target;
    e.persist();
    this.setState({
      [name]: value
    })
  };

  render() {
    const path = createBrowserHistory().location.pathname
    return (
      <Section>
        <Image src={logo} />
        <Text>えほん アバター作成ログイン</Text>
        {
          this.state.error &&
          <AlertComponent containerStyle={{
            marginBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }} error={this.state.error ? true : false} message={this.state.error} />
        }
        <WrapperInput>
          <Label>アバター作成ID</Label>
          <Input placeholder='' name='username' type='text' onChange={this.onChange} onKeyDown={this.onKeyDown} />
        </WrapperInput>
        <WrapperInput>
          <Label>パスワード</Label>
          <Input placeholder='' name='password' type='password' onChange={this.onChange} onKeyDown={this.onKeyDown} />
        </WrapperInput>
        <Button onClick={this._login}>ログイン</Button>
        <Label>パスワードを忘れた方、変更を希望の方は
          <a href='https://share.hsforms.com/1oBhn_MDRTy-56aOtJTl8MQddvno' target='_blank'>
            こちら
          </a>
        </Label>
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.loginAvatar.data,
    error: state.loginAvatar.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(LoginAvatarActions.loginAvatarRequest(params))
  }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginAvatar)))
