import React from 'react'
import { translate } from 'react-i18next'
import logoBrand from '../../../../../Images/logoBrand.png'

const LoginInfo = () => {
    

    return (
        <>
            <div className='wrapper-login-info'>
                <div className='logo-brand'>
                    <img src={logoBrand} alt='logoBrand' />
                    <span>えほん管理システム（店舗管理）</span>
                </div>
                <div className='store-info'>
                <span className='store-info-name'>ガリバー店舗管理者</span>
                </div>
            </div>
        </>
    )
}

export default translate('translations')(LoginInfo)
