import { createBrowserHistory } from "history";

const path = createBrowserHistory().location.pathname
let title = '';
switch (path) {
  case '/store-list/login':
    title = 'ログイン｜ガリバーえほん店舗管理システム'
    break;
  case '/avatar_management':
    title = 'ダッシュボード｜ガリバーえほん店舗管理システム'
    break;
  case '/staff_management':
    title = 'スタッフ一覧｜ガリバーえほん店舗管理システム'
    break;
  case '/store/login':
    title = 'ログイン｜アバターカスタムシステム'
    break;
  case '/add_info_avatar':
    title = 'アバター情報入力｜アバターカスタムシステム'
    break;
  case '/book-customize':
    title = 'アバター作成｜アバターカスタムシステム'
    break;
  case '/avatar_page':
    title = 'アバター画像プレゼント'
    break;
  default:
    title = 'ガリバーえほん管理システム'
}
const AppConfig = {
  appName: title
}

export default AppConfig
