import { call, put } from 'redux-saga/effects'
import UploadActions from '../Redux/UploadRedux'
import LoginActions from '../Redux/LoginRedux'
export function * upload (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res && res.message === 'Unauthenticated.') {
      yield put(LoginActions.loginFailure())
      return
    }
    console.log('SAGA upload success: ', JSON.stringify(res))
    if (res.success) {
      yield put(UploadActions.uploadSuccess(res.data))
    } else {
      yield put(UploadActions.uploadFailure(res.message))
    }
  } catch (error) {
    yield put(UploadActions.uploadFailure(error.message))
  }
}
