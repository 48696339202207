import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  adminLoginRequest: ['params'],
  adminLoginGoogle: ['data'],
  adminLoginSuccess: ['data'],
  adminLoginFailure: ['error'],
  adminLogoutRequest: null,
  adminLogoutSuccess: null,
  adminLogoutFailure: ['error'],
  clearData: null
})

export const AdminLoginTypes = Types
export default Creators
// selector
export const adminLoginTokenSelector = state => state.adminLogin.data
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  error: null,
  fetching: false,
  loggingOut: false
})

/* ------------- Reducers ------------- */

export const adminLoginRequest = state => state.merge({ fetching: true, error: null, data: null })

export const adminLoginSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })

export const adminLoginFailure = (state, { error }) => state.merge({ fetching: false, error, data: null })

export const adminLogoutRequest = state => state.merge({ loggingOut: true })

export const adminLogoutSuccess = state => INITIAL_STATE

export const adminLogoutFailure = (state, { error }) => state.merge({ loggingOut: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADMIN_LOGIN_REQUEST]: adminLoginRequest,
  [Types.ADMIN_LOGIN_SUCCESS]: adminLoginSuccess,
  [Types.ADMIN_LOGIN_FAILURE]: adminLoginFailure,
  [Types.ADMIN_LOGOUT_REQUEST]: adminLogoutRequest,
  [Types.ADMIN_LOGOUT_SUCCESS]: adminLogoutSuccess,
  [Types.ADMIN_LOGOUT_FAILURE]: adminLogoutFailure
})
