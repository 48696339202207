import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginAvatarRequest: ['params'],
  loginAvatarGoogle: ['data'],
  loginAvatarSuccess: ['data'],
  loginAvatarFailure: ['error'],
  // logoutAvatarRequest: null,
  // logoutAvatarSuccess: null,
  // logoutAvatarFailure: ['error'],
  clearData: null
})

export const LoginAvatarTypes = Types
export default Creators
// selector
export const loginAvatarTokenSelector = state => state.loginAvatar.data
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  error: null,
  fetching: false,
  loggingOut: false
})

/* ------------- Reducers ------------- */

export const loginAvatarRequest = state => state.merge({ fetching: true, error: null, data: null })

export const loginAvatarSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })

export const loginAvatarFailure = (state, { error }) => state.merge({ fetching: false, error, data: null })

// export const logoutAvatarRequest = state => state.merge({ loggingOut: true })

// export const logoutAvatarSuccess = state => INITIAL_STATE

// export const logoutAvatarFailure = (state, { error }) => state.merge({ loggingOut: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_AVATAR_REQUEST]: loginAvatarRequest,
  [Types.LOGIN_AVATAR_SUCCESS]: loginAvatarSuccess,
  [Types.LOGIN_AVATAR_FAILURE]: loginAvatarFailure,
  // [Types.LOGOUT_AVATAR_REQUEST]: logoutAvatarRequest,
  // [Types.LOGOUT_AVATAR_SUCCESS]: logoutAvatarSuccess,
  // [Types.LOGOUT_AVATAR_FAILURE]: logoutAvatarFailure
})
