import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import api from '../Services/Api'
/* ------------- Types ------------- */
import { ShopifyTypes } from '../Redux/ShopifyRedux'
import { CmsModelListTypes } from '../Redux/CmsModelListRedux'
import { CmsModelDeleteTypes } from '../Redux/CmsModelDeleteRedux'
import { CmsModelAddTypes } from '../Redux/CmsModelAddRedux'
import { CmsModelUpdateTypes } from '../Redux/CmsModelUpdateRedux'
import { UserListTypes } from '../Redux/UserListRedux'
import { UploadTypes } from '../Redux/UploadRedux'
import { ProfileTypes } from '../Redux/ProfileRedux'
import { ForgotPasswordTypes } from '../Redux/ForgotPasswordRedux'
import { UserTypes } from '../Redux/UserRedux'
import { StoreListTypes } from '../Redux/StoreListRedux'
import { AvatarListTypes } from '../Redux/AvatarListRedux'
import { StoreTypes } from '../Redux/StoreRedux'
import { DeleteStoreTypes } from '../Redux/StoreDeleteRedux'
import { StartupTypes } from '../Redux/StartupRedux'
import { LoginTypes } from '../Redux/LoginRedux'
import { AdminLoginTypes } from '../Redux/AdminLoginRedux'
import { LoginAvatarTypes } from '../Redux/LoginAvatarRedux'
import { LoginStoreTypes } from '../Redux/LoginStoreRedux'
// import { RegisterTypes } from '../Redux/RegisterRedux'
/* ------------- Sagas ------------- */
import { shopifyAddProduct, shopifyCreateToken } from './ShopifySaga'
import { cmsModelList } from './CmsModelListSaga'
import { cmsModelDelete } from './CmsModelDeleteSaga'
import { cmsModelAdd } from './CmsModelAddSaga'
import { cmsModelUpdate } from './CmsModelUpdateSaga'
import { userList } from './UserListSaga'
import { storeList } from './StoreListSaga'
import { avatarList } from './AvatarListSaga';
import { deleteStore } from './StoreDeleteSaga';
import { addStore } from './StoreSaga'
import { upload } from './UploadSaga'
import { profileUpdateUsername, profileChangePassword } from './ProfileSaga'
import { forgotPassword } from './ForgotPasswordSaga'
import { user, userUpdate } from './UserSaga'
import { startup } from './StartupSagas'
import { login, logout } from './LoginSagas'
import { adminLogin, adminLogout } from './AdminLoginSagas'
import { loginAvatar} from './LoginAvatarSagas'
import { loginStore} from './LoginStoreSagas'
// import { register } from './RegisterSaga'
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup, api),
    // tool generated sagas
    takeLatest(ShopifyTypes.SHOPIFY_ADD_PRODUCT_REQUEST, shopifyAddProduct, api.shopifyAddProduct),
    takeLatest(ShopifyTypes.SHOPIFY_CREATE_TOKEN_REQUEST, shopifyCreateToken, api.shopifyCreateToken),
    takeLatest(CmsModelListTypes.CMS_MODEL_LIST_REQUEST, cmsModelList, api.cmsModelList),
    takeLatest(CmsModelDeleteTypes.CMS_MODEL_DELETE_REQUEST, cmsModelDelete, api.cmsModelDelete),
    takeLatest(CmsModelAddTypes.CMS_MODEL_ADD_REQUEST, cmsModelAdd, api.cmsModelAdd),
    takeLatest(CmsModelUpdateTypes.CMS_MODEL_UPDATE_REQUEST, cmsModelUpdate, api.cmsModelUpdate),
    takeLatest(UserListTypes.USER_LIST_REQUEST, userList, api.userList),
    takeLatest(StoreListTypes.STORE_LIST_REQUEST, storeList, api.storeList),
    takeLatest(StoreTypes.ADD_STORE_REQUEST, addStore, api.addStore),

    takeLatest(AvatarListTypes.AVATAR_LIST_REQUEST, avatarList, api.avatarList),
    
    takeLatest(UploadTypes.UPLOAD_REQUEST, upload, api.upload),
    takeLatest(UserTypes.USER_UPDATE, userUpdate, api.userUpdate),
    takeLatest(UserTypes.USER_REQUEST, user, api.adminAuthUser),
    
    // Login
    takeLatest(LoginTypes.LOGIN_REQUEST, login, api.login),
    takeLatest(AdminLoginTypes.ADMIN_LOGIN_REQUEST, adminLogin, api.adminLogin),
    takeLatest(LoginAvatarTypes.LOGIN_AVATAR_REQUEST, loginAvatar, api.loginAvatar),
    takeLatest(LoginStoreTypes.LOGIN_STORE_REQUEST, loginStore, api.loginStore),
    takeLatest(LoginTypes.LOGOUT_REQUEST, logout, api.logout),
    takeLatest(AdminLoginTypes.ADMIN_LOGOUT_REQUEST, adminLogout, api.adminLogout),

    // Register
    // takeLatest(RegisterTypes.REGISTER_REQUEST, register, api.register),

    // Forgot Password
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api.forgotPassword),

    // Profile: update username
    takeLatest(ProfileTypes.PROFILE_UPDATE_USERNAME_REQUEST, profileUpdateUsername, api.updateUsername),

    // Profile: change password
    takeLatest(ProfileTypes.PROFILE_CHANGE_PASSWORD_REQUEST, profileChangePassword, api.changePassword),

    takeLatest(DeleteStoreTypes.STORE_DELETE_REQUEST, deleteStore, api.deleteStore),
  ])
}
