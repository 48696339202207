
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  profileUpdateUsernameRequest: ['params'],
  profileUpdateUsernameSuccess: ['changeUsernameData'],
  profileChangePasswordRequest: ['params'],
  profileChangePasswordSuccess: ['changePasswordData'],
  profileFailure: ['error'],
  clearData: null
})

export const ProfileTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  changeUsernameData: null,
  changePasswordData: null,
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */
export const profileUpdateUsernameRequest = state => state.merge({ fetching: true, error: null, changeUsernameData: null, changePasswordData: null })
export const profileUpdateUsernameSuccess = (state, { changeUsernameData }) => state.merge({ fetching: false, error: null, changeUsernameData })

export const profileChangePasswordRequest = state => state.merge({ fetching: true, error: null, changeUsernameData: null, changePasswordData: null })
export const profileChangePasswordSuccess = (state, { changePasswordData }) => state.merge({ fetching: false, error: null, changePasswordData })

export const profileFailure = (state, { error }) => state.merge({ fetching: false, changeUsernameData: null, changePasswordData: null, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROFILE_UPDATE_USERNAME_REQUEST]: profileUpdateUsernameRequest,
  [Types.PROFILE_UPDATE_USERNAME_SUCCESS]: profileUpdateUsernameSuccess,
  [Types.PROFILE_CHANGE_PASSWORD_REQUEST]: profileChangePasswordRequest,
  [Types.PROFILE_CHANGE_PASSWORD_SUCCESS]: profileChangePasswordSuccess,
  [Types.PROFILE_FAILURE]: profileFailure
})
