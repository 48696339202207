import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import QRCode from 'qrcode.react'
import Modal from '../../../../../Components/modal/Modal'
import logoBrand from '../../../../../Images/logoBrand.png'
import { LOCAL_STORAGE } from '../../../../../Utils/constants'
import { createBrowserHistory } from "history";

const LoginInfo = ({ user }) => {
    const [id, setID] = useState(null);
    const [url, setUrl] = useState(null);
    const [isShown, setIsShown] = useState(false);

    const path = createBrowserHistory().location.pathname

    const getStoreId = async () => {
        const id = await localStorage.getItem(LOCAL_STORAGE.STORE_ID);
        const url = await localStorage.getItem(LOCAL_STORAGE.STORE_URL);
        if (id) {
            setID(id);
        }
        if (url) {
            setUrl(url);
        }
    }

    useEffect(() => {
        getStoreId()
    }, [])

    const openQrCode = () => {
        // setIsShown(!isShown);
        setIsShown(false);
    }

    return (
        <>
            <div className='wrapper-login-info'>
                <div className='logo-brand'>
                    <img src={logoBrand} alt='logoBrand' />
                    <span>{path === '/add_info_avatar' ? 'アバター作成' : 'えほん管理システム'}</span>
                </div>
                {
                    path === '/add_info_avatar' ? null :
                        <div className='store-info'>
                            <span className='store-info-name'>{user?.store_name}</span>
                            {user?.store_id &&
                                <span style={{ cursor: 'default' }} onClick={openQrCode} className='store-info-id'>店舗ID: {user?.store_id}</span>
                            }
                        </div>
                }
            </div>
            {(isShown) &&
                <Modal
                    isShown={isShown}
                    onCancel={openQrCode}
                    onAction={openQrCode}
                    children={
                        <QRCode
                            id='qrcode'
                            value={url}
                            size={290}
                            level={'H'}
                            includeMargin={true}
                        />
                    }
                />
            }
        </>
    )
}

export default translate('translations')(LoginInfo)
