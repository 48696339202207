import React from "react"
import { publicRoutes } from '../../routes'
import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../../Navigation/PrivateRoute'
import Header from "./Header"
import { LOCAL_STORAGE } from "../../../Utils/constants"


const CommonLayout = () => {
    const role = localStorage.getItem(LOCAL_STORAGE.ROLE)

    return (
        <div className="wrapperCommonLayout">
            <Header />
            <div className='content'>
                <Switch>
                    {publicRoutes.map((route, idx) => {
                        if (role === 'admin') {
                            return (
                                <Redirect from='/' to='/admin' />
                            )
                        } else {
                            return (
                                route.component && (<Route key={idx} {...route} />)
                            )
                        }
                    })}
                    <Redirect from='/' to='/store/login' />
                </Switch>
            </div>
        </div>
    )
}

export default CommonLayout