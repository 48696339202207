import React from "react"
import { routes } from '../../routes'
import { Redirect, Switch } from 'react-router-dom'
import PrivateRoute from '../../../Navigation/PrivateRoute'
import Header from "./Header"

const AdminLayout = () => {
    return (
        <div className="wrapperCommonLayout">
            <Header />
            <div className='content'>
                <Switch>
                    {routes.map((route, idx) => {
                        return (
                            route.component ? (<PrivateRoute key={idx} {...route} />) : (null)
                        )
                    })}
                    <Redirect from='/' to='/admin/store_management' />
                </Switch>
            </div>
        </div>
    )
}

export default AdminLayout