import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginStoreRequest: ['params'],
  loginStoreGoogle: ['data'],
  loginStoreSuccess: ['data'],
  loginStoreFailure: ['error'],
  clearData: null
})

export const LoginStoreTypes = Types
export default Creators
// selector
export const loginStoreTokenSelector = state => state.loginStore.data
/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  error: null,
  fetching: false,
  loggingOut: false
})

/* ------------- Reducers ------------- */

export const loginStoreRequest = state => state.merge({ fetching: true, error: null, data: null })

export const loginStoreSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })

export const loginStoreFailure = (state, { error }) => state.merge({ fetching: false, error, data: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_STORE_REQUEST]: loginStoreRequest,
  [Types.LOGIN_STORE_SUCCESS]: loginStoreSuccess,
  [Types.LOGIN_STORE_FAILURE]: loginStoreFailure,
})
