
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addStoreRequest: ['params'],
  addStoreSuccess: ['data'],
  addStoreFailure: ['error'],
  clearData: null
})

export const StoreTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  fetching: false,
  fetchingUpdate: false
})

/* ------------- Reducers ------------- */

export const addStoreRequest = state => state.merge({ fetching: true, error: null })
export const addStoreSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const addStoreFailure = (state, { error }) => state.merge({ fetching: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_STORE_REQUEST]: addStoreRequest,
  [Types.ADD_STORE_SUCCESS]: addStoreSuccess,
  [Types.ADD_STORE_FAILURE]: addStoreFailure,
})
