
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  storeListRequest: ['params'],
  storeListSuccess: ['data'],
  storeListFailure: ['error'],
  clearData: null
})

export const StoreListTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const storeListRequest = state => state.merge({ fetching: true, error: null })
export const storeListSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const storeListFailure = (state, { error }) => state.merge({ fetching: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STORE_LIST_REQUEST]: storeListRequest,
  [Types.STORE_LIST_SUCCESS]: storeListSuccess,
  [Types.STORE_LIST_FAILURE]: storeListFailure,
})
