import I18n from '../I18n'

export default {
  items: [
    {
      name: I18n.t('dashboard'),
      url: '/admin/dashboard',
      icon: 'icon-home'
    },
    {
      name: I18n.t('tab_profile'),
      url: '/admin/profile',
      icon: 'fa fa-gear'
    },
    {
      name: I18n.t('tab_users'),
      url: '/admin/users',
      icon: 'fa fa-user'
    },
    {
      name: I18n.t('tab_master_book'),
      url: '/admin/master_book',
      icon: 'icon-pie-chart'
    },
    {
      name: I18n.t('tab_products'),
      url: '/admin/shopify/products',
      icon: 'icon-pie-chart'
    },
    {
      name: I18n.t('tab_add_product'),
      url: '/admin/shopify/add_product',
      icon: 'icon-pie-chart'
    },
    {
      name: I18n.t('tab_store_management'),
      url: '/admin/store_management',
      icon: 'fa fa-user'
    },
  ]
}
