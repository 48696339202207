import React, { useState, useEffect } from 'react'
import { translate } from 'react-i18next'
import IconSmileyFace from '../../../Images/IconSmileyFace.png'
import IconMoutain from '../../../Images/IconMoutain.png'
import api from '../../../Services/Api'
import { Section, WrapperImageShow, Image, TextTitle, TextTitleDescribe, WrapperImageCar, WrapperImageAwait, WrapperImageCarShow, WrapperImageCarAwait, ImageTitle, ImageHead, ImageCarAwait, Wrapper } from './style'
import mergeImages from 'merge-images';
import CircularProgress from '@mui/material/CircularProgress';
import ApiConfig from '../../../Config/ApiConfig'

const AvatarPage = (props) => {
  const [avatarImage, setAvatarImage] = useState([]);
  const [arrayImageCarShow, setArrayImageCarShow] = useState([]);
  const [arrayImageCarAwait, setArrayImageCarAwait] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initData()
    document.title = 'アバター画像プレゼント'
  }, [])

  const initData = async () => {
    setLoading(true);
    const res = await api.getListStickies(props.match.params.id);
    if (res && res.data && res.data.data && res.data.data.length > 0) {
      if (checkDataImage(res?.data?.data)) {
        setAvatarImage(res?.data?.data)
        setLoading(false);
      } else {
        setAvatarImage(res?.data?.data[0])
        const data = [...res?.data?.data];
        const arrayAwait = [...arrayImageCarAwait];
        const arrayShow = [...arrayImageCarShow];
        for (let index = 0; index < data.length; index++) {
          if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            let position = null;
            if ([1, 2].includes(index)) {
              position = { src: data[index], x: 50, y: 90 };
            }
            if ([3].includes(index)) {
              position = { src: data[index], x: -70, y: -140 };
            }
            if ([4].includes(index)) {
              position = { src: data[index], x: 190, y: 70 };
            }
            if ([5].includes(index)) {
              position = { src: data[index], x: -70, y: -140 };
            }
            if ([6].includes(index)) {
              position = { src: data[index], x: -30, y: 80 };
            }
            if ([7, 8].includes(index)) {
              position = { src: data[index], x: -30, y: 150 };
            }
            if ([8].includes(index)) {
              position = { src: data[index], x: -30, y: -150 };
            }
            if ([4].includes(index)) {
              const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
              arrayShow.push(b64);
            } else {
              const b64 = await mergeImages([position, textImage], { crossOrigin: "Anonymous" })
              arrayShow.push(b64);
            }
          } else if ([9, 11].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            let position = null;
            if (index === 9) {
              position = { src: data[index], x: 50, y: 950 };
            } else {
              position = { src: data[index], x: 140, y: 1000 };
            }
            const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
            arrayAwait.push(b64);
          } else if ([10].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            const b64 = await mergeImages(
              [textImage, { src: data[index], x: 0, y: 300 }, require(`../../../Images/stamp_10_pats.png`)],
              { crossOrigin: "Anonymous" })
            arrayAwait.push(b64);
          }
        }
        setArrayImageCarShow(arrayShow)
        setArrayImageCarAwait(arrayAwait)
        setLoading(false);
      }
    }
    setLoading(false);
  }

  const checkDataImage = (data) => {
    if (data.length > 0 && data[0].includes('avatar_page')) {
      return true
    } else {
      return false
    }
  }

  return (
    <Section>
      {loading ?
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100vh' }}>
          <CircularProgress />
        </div> :
        <Wrapper>
          <WrapperImageCar>
            {checkDataImage(avatarImage) ?
              <ImageHead
                src={`${ApiConfig.baseURL}/${avatarImage[0]}`}
                alt='ImageCar'
              />
              :
              <ImageHead
                src={avatarImage}
                alt='ImageCar'
              />
            }
          </WrapperImageCar>
          <WrapperImageShow>
            <ImageTitle src={IconSmileyFace} alt='IconSmileyFace' />
            <TextTitle>LINEスタンプ風画像</TextTitle>
          </WrapperImageShow>
          <TextTitleDescribe>タップして表示された画像を保存してください。</TextTitleDescribe>
          <>
            {checkDataImage(avatarImage) ?
              <>
                {avatarImage.slice(1, 9).map((item, i) =>
                  <WrapperImageCarShow key={i}>
                    <Image src={`${ApiConfig.baseURL}/${item}`} alt={item.name} />
                  </WrapperImageCarShow>
                )}
              </> :
              <>
                {arrayImageCarShow.map((item, i) =>
                  <WrapperImageCarShow key={i}>
                    <Image src={item} alt={item.name} />
                  </WrapperImageCarShow>
                )}
              </>
            }
          </>
          <WrapperImageAwait>
            <WrapperImageShow>
              <ImageTitle src={IconMoutain} alt='IconMoutain' />
              <TextTitle>待ち受け画像</TextTitle>
            </WrapperImageShow>
            <TextTitleDescribe>タップして表示された画像を保存してください。</TextTitleDescribe>
          </WrapperImageAwait>
          <>
            {checkDataImage(avatarImage) ?
              <>
                {avatarImage.slice(9, 12).map((item, i) =>
                  <WrapperImageAwait key={i}>
                    <ImageCarAwait src={`${ApiConfig.baseURL}/${item}`} alt={item.name} />
                  </WrapperImageAwait>
                )}
              </> :
              <>
                {arrayImageCarAwait.map((item, i) =>
                  <WrapperImageAwait key={i}>
                    <ImageCarAwait src={item} alt={item.name} />
                  </WrapperImageAwait>
                )}
              </>
            }
          </>
          {/* {avatarImage.slice(9, 12).map((item, i) =>
            <WrapperImageCarAwait key={i}>
              <ImageCarAwait src={`${ApiConfig.baseURL}/${item}`} alt={item.name} />
            </WrapperImageCarAwait>
          )} */}
        </Wrapper>
      }
    </Section>
  )
}

export default translate('translations')(AvatarPage)