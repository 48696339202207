import { call, put } from 'redux-saga/effects'
import AdminLoginActions from '../Redux/AdminLoginRedux'
import UserActions from '../Redux/UserRedux'
import { LOCAL_STORAGE } from '../Utils/constants'

export function * adminLogin (loginAPI, { params }) {
  try {
    const res = yield call(loginAPI, params)
    if (res && res.success) {
      localStorage.setItem(LOCAL_STORAGE.ROLE, res.data.role)
      yield put(AdminLoginActions.adminLoginSuccess(res.data.access_token))
      if (res.data.user) {
        yield put(UserActions.userSuccess(res.data.user))
      }
      params.history.push('/avatar_management')
    } else {
      yield put(AdminLoginActions.adminLoginFailure(res.message))
    }
  } catch (error) {
    yield put(AdminLoginActions.adminLoginFailure(error.message))
  }
}

export function * adminLogout (logoutAPI) {
  try {
    yield call(logoutAPI)
    yield put(AdminLoginActions.adminLogoutSuccess())
  } catch (error) {
    yield put(AdminLoginActions.adminLogoutFailure(error.message))
  }
}
