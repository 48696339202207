import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0;
    background-color: ${(props) => (props.background ? props.background : '#fdf2f1')};
    border-radius: 4px;
    width: ${(props) => (props.width ? props.width : '361px')};
`;

const Icon = styled.img`
    margin-right: 4px;
`;

const Message = styled.p`
    margin: 0;
    font-size: 14px;
    color: ${(props) => (props.error ? '#d93025' : '#14784a')};
`;

export {
    Container,
    Icon,
    Message
};
