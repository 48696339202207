import { call, put } from 'redux-saga/effects'
import LoginAvatarActions from '../Redux/LoginAvatarRedux'
import UserActions from '../Redux/UserRedux'
import { LOCAL_STORAGE } from '../Utils/constants'

export function * loginAvatar(loginAPI, { params }) {
  try {
    const res = yield call(loginAPI, params);
    if (res && res.success) {
      localStorage.setItem(LOCAL_STORAGE.ROLE, res.data.role)
      yield put(LoginAvatarActions.loginAvatarSuccess(res.data.access_token))
      if (res.data.info) {
        yield put(UserActions.userSuccess(res.data.info));
      }
    } else {
      yield put(LoginAvatarActions.loginAvatarFailure(res.message))
    }
  } catch (error) {
    yield put(LoginAvatarActions.loginAvatarFailure(error.message))
  }
}