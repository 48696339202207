import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Backdrop, Button, IMG, StyledModal, WrapButton, Wrapper,} from './style';
import close from '../../Containers/assets/icons/close.svg';

const Modal = ({
    isShown,
    onCancel,
    onAction,
    children
}) => {
    const clickAction = () => {
        onAction();
    }

    const modal = (
        <>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <IMG onClick={onCancel} src={close} />
                    {children}
                    <WrapButton>
                        <Button
                            onClick={clickAction}>
                            OK
                        </Button>
                    </WrapButton>
                </StyledModal>
            </Wrapper>
        </>
    );
    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
