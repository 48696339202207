import { call, put } from 'redux-saga/effects'
import LoginStoreActions from '../Redux/LoginStoreRedux'
import UserActions from '../Redux/UserRedux'
import { LOCAL_STORAGE } from '../Utils/constants'

export function * loginStore(loginAPI, { params }) {
  try {
    const res = yield call(loginAPI, params);
    if (res && res.success) {
      localStorage.setItem(LOCAL_STORAGE.ROLE, res.data.role)
      yield put(LoginStoreActions.loginStoreSuccess(res.data.access_token))
      if (res.data.info) {
        yield put(UserActions.userSuccess(res.data.info));
      }
    } else {
      yield put(LoginStoreActions.loginStoreFailure(res.message))
    }
  } catch (error) {
    yield put(LoginStoreActions.loginStoreFailure(error.message))
  }
}