import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import api from '../../../Services/Api';
import { ImageContainer, ImageMain, ImageSection } from './style';
import mergeImages from 'merge-images';
import CircularProgress from '@mui/material/CircularProgress';
import ApiConfig from '../../../Config/ApiConfig'

const AvatarPage = (props) => {
  const [avatarImage, setAvatarImage] = useState();
  const [arrayImageCarShow, setArrayImageCarShow] = useState([]);
  const [arrayImageCarAwait, setArrayImageCarAwait] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initData()
    document.title = 'アバター画像プレゼント'
  }, [])

  const initData = async () => {
    setLoading(true);
    const res = await api.getListStickies(props.match.params.id);
    if (res && res.data && res.data.data && res.data.data.length > 0) {
      if (checkDataImage(res?.data?.data)) {
        setAvatarImage(res?.data?.data)
        setLoading(false);
      } else {
        setAvatarImage(res?.data?.data[0])
        const data = [...res?.data?.data];
        const arrayAwait = [...arrayImageCarAwait];
        const arrayShow = [...arrayImageCarShow];
        for (let index = 0; index < data.length; index++) {
          if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            let position = null;
            if ([1, 2].includes(index)) {
              position = { src: data[index], x: 50, y: 90 };
            }
            if ([3].includes(index)) {
              position = { src: data[index], x: -70, y: -140 };
            }
            if ([4].includes(index)) {
              position = { src: data[index], x: 190, y: 70 };
            }
            if ([5].includes(index)) {
              position = { src: data[index], x: -70, y: -140 };
            }
            if ([6].includes(index)) {
              position = { src: data[index], x: -30, y: 80 };
            }
            if ([7, 8].includes(index)) {
              position = { src: data[index], x: -30, y: 150 };
            }
            if ([8].includes(index)) {
              position = { src: data[index], x: -30, y: -150 };
            }
            if ([4].includes(index)) {
              const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
              arrayShow.push(b64);
            } else {
              const b64 = await mergeImages([position, textImage], { crossOrigin: "Anonymous" })
              arrayShow.push(b64);
            }
          } else if ([9, 11].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            let position = null;
            if (index === 9) {
              position = { src: data[index], x: 50, y: 950 };
            } else {
              position = { src: data[index], x: 140, y: 1000 };
            }
            const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
            arrayAwait.push(b64);
          } else if ([10].includes(index)) {
            const textImage = require(`../../../Images/stamp_0${index}_text.png`);
            const b64 = await mergeImages(
              [textImage, { src: data[index], x: 0, y: 300 }, require(`../../../Images/stamp_10_pats.png`)],
              { crossOrigin: "Anonymous" })
            arrayAwait.push(b64);
          }
        }
        setArrayImageCarShow(arrayShow)
        setArrayImageCarAwait(arrayAwait)
        setLoading(false);
      }
    }
    setLoading(false);
  }

  const checkDataImage = (data) => {
    if (data.length > 0 && data[0].includes('avatar_page')) {
      return true
    } else {
      return false
    }
  }

  return (
    <ImageSection>
      {loading ?
        <CircularProgress />
        :
        <>
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_main.png` &&
            <>
              {checkDataImage(avatarImage) ?
                <ImageMain
                  src={`${ApiConfig.baseURL}/${avatarImage[0]}`}
                  alt='ImageCar'
                />
                :
                <ImageMain
                  src={avatarImage}
                  alt='ImageCar'
                />
              }
            </>
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_thanks.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[0]} alt={arrayImageCarShow[0].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_thanks.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[1]}`} alt={avatarImage[1].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_good.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[1]} alt={arrayImageCarShow[1].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_good.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[2]}`} alt={avatarImage[2].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_ok.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[2]} alt={arrayImageCarShow[2].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_ok.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[3]}`} alt={avatarImage[3].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_smile.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[3]} alt={arrayImageCarShow[3].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_smile.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[4]}`} alt={avatarImage[4].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_work.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[4]} alt={arrayImageCarShow[4].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_work.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[5]}`} alt={avatarImage[5].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_what.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[5]} alt={arrayImageCarShow[5].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_what.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[6]}`} alt={avatarImage[6].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_okcar.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[6]} alt={arrayImageCarShow[6].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_okcar.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[7]}`} alt={avatarImage[7].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_really.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarShow[7]} alt={arrayImageCarShow[7].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_really.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[8]}`} alt={avatarImage[8].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall01.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarAwait[0]} alt={arrayImageCarAwait[0].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall01.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[9]}`} alt={avatarImage[9].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall02.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarAwait[1]} alt={arrayImageCarAwait[1].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall02.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[10]}`} alt={avatarImage[10].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall03.png` && arrayImageCarShow.length > 0 &&
            <ImageContainer src={arrayImageCarAwait[2]} alt={arrayImageCarAwait[2].name} />
          }
          {window.location.pathname === `/avatar_page/${props.match.params.id}/${props.match.params.id}_wall03.png` && avatarImage.length > 0 &&
            <ImageContainer src={`${ApiConfig.baseURL}/${avatarImage[11]}`} alt={avatarImage[11].name} />
          }
        </>

      }
    </ImageSection>
  )
}

export default translate('translations')(AvatarPage)