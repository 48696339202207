import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
// import { reducer as formReducer } from 'redux-form'

import ReduxPersistConfig from '../Config/ReduxPersistConfig'
import configureStore from './CreateStore'
import rootSaga from '../Sagas'

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  const appReducer = combineReducers({
    shopify: require('./ShopifyRedux').reducer,
    cmsModelList: require('./CmsModelListRedux').reducer,
    cmsModelDelete: require('./CmsModelDeleteRedux').reducer,
    cmsModelAdd: require('./CmsModelAddRedux').reducer,
    cmsModelUpdate: require('./CmsModelUpdateRedux').reducer,
    userList: require('./UserListRedux').reducer,
    storeList: require('./StoreListRedux').reducer,
    avatarList: require('./AvatarListRedux').reducer,
    storeDelete: require('./StoreDeleteRedux').reducer,
    store: require('./StoreRedux').reducer,
    upload: require('./UploadRedux').reducer,
    profile: require('./ProfileRedux').reducer,
    forgotPassword: require('./ForgotPasswordRedux').reducer,
    user: require('./UserRedux').reducer,
    login: require('./LoginRedux').reducer,
    adminLogin: require('./AdminLoginRedux').reducer,
    loginAvatar: require('./LoginAvatarRedux').reducer,
    loginStore: require('./LoginStoreRedux').reducer,
    register: require('./RegisterRedux').reducer
  })

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      state = undefined
    } else if (action.type === 'CLEAR_DATA') {
      state = {
        ...appReducer({}, {}),
        login: (state && state.login) || {},
        adminLogin: (state && state.adminLogin) || {},
        loginAvatar: (state && state.loginAvatar) || {},
        loginStore: (state && state.loginStore) || {},
        startup: (state && state.startup) || {}
      }
    }
    return appReducer(state, action)
  }

  const persistedReducer = persistReducer(ReduxPersistConfig.storeConfig, rootReducer)

  return configureStore(persistedReducer, rootSaga)
}
