import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminLoginActions from '../../../../Redux/AdminLoginRedux'
import Utils from '../../Custom/Utils'
import { LOCAL_STORAGE } from '../../../../Utils/constants'
import AlertComponent from '../../../../Components/alert/Alert'
import { translate } from 'react-i18next'
import { Section, Image, Text, Label, Input, WrapperInput, Button } from './style'
import logo from '../../../assets/img/brand/gulliver.svg'
import Alert from 'react-s-alert'
import { createBrowserHistory } from "history";
import { withRouter } from 'react-router-dom'


class AdminLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isAuthenticated: false,
      user: null,
      error: '',
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const path = createBrowserHistory().location.pathname
      const textError = <p>ログインに失敗しました。<br />IDまたはパスワードが異なります。</p>
    if (props.error) {

      this.setState({
        error: textError
      })
    }

    const role = localStorage.getItem(LOCAL_STORAGE.ROLE)

    if (props.data) {
      if (role === 'admin') {
        this.props.history.push('/admin')
      }

      if (role === 'store') {
        // this.props.history.push('/')
      }
    }
  }
  
  _verify() {
    const errorMessages = []

    // if (!this.state.username) {
    //   errorMessages.push(this.props.t('register_storeid_is_required'))
    // }

    // if (!this.state.password) {
    //   errorMessages.push(this.props.t('register_password_is_required'))
    // }

    // for (let id = 0; id < errorMessages.length; id++) {
    //   const errorMessage = errorMessages[id]
    //   Alert.error(errorMessage, {
    //     position: 'bottom-right',
    //     effect: 'bouncyflip'
    //   })
    // }
    const path = createBrowserHistory().location.pathname
    const textError = <p>ログインに失敗しました。<br />IDまたはパスワードが異なります。</p>

    if (!this.state.username) {
      errorMessages.push(this.setState({
        error: textError
      }))
    }

    if (!this.state.password) {
      errorMessages.push(this.setState({
        error: textError
      }))
    }

    return errorMessages.length ? 0 : 1
  }

  _login = () => {
    if (!this._verify()) return

    this.props.login({
      username: this.state.username,
      password: this.state.password,
      history: this.props.history
    })
  }

  onKeyDown = (event) => {
    
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this._login();
    }
  }

  onChange = (e) => {
    const { value, name } = e.target;
    e.persist();
    this.setState({
      [name]: value
    })
  };

  

  render() {
    const path = createBrowserHistory().location.pathname
    
    return (
      <Section>
        <Image src={logo} />
        <Text>えほん管理システム（店舗管理）</Text>
        {
          this.state.error &&
          <AlertComponent containerStyle={{
            marginBottom: '24px',
            paddingLeft: '16px',
            paddingRight: '16px',
          }} error={this.state.error ? true : false} message={this.state.error} />
        }
        <WrapperInput>
        <Label>ID</Label>
          <Input placeholder='' name='username' type='text' onChange={this.onChange} onKeyDown={this.onKeyDown}/>
        </WrapperInput>
        <WrapperInput>
          <Label>パスワード</Label>
          <Input placeholder='' name='password' type='password' onChange={this.onChange} onKeyDown={this.onKeyDown}/>
        </WrapperInput>
        <Button onClick={this._login}>ログイン</Button>
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.adminLogin.data,
    error: state.adminLogin.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => dispatch(AdminLoginActions.adminLoginRequest(params))
  }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminLogin)))
