
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  avatarListRequest: ['params'],
  avatarListSuccess: ['data'],
  avatarListFailure: ['error'],
  clearData: null
})

export const AvatarListTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const avatarListRequest = state => state.merge({ fetching: true, error: null })
export const avatarListSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const avatarListFailure = (state, { error }) => state.merge({ fetching: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.AVATAR_LIST_REQUEST]: avatarListRequest,
  [Types.AVATAR_LIST_SUCCESS]: avatarListSuccess,
  [Types.AVATAR_LIST_FAILURE]: avatarListFailure,
})
