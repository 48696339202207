import React, { Component } from 'react'
import { translate } from 'react-i18next'
import Unity, { UnityContext } from 'react-unity-webgl'
import api from '../../../Services/Api'
import { Progress, Row, Col } from 'reactstrap'
import mergeImages from 'merge-images';

const PUBLIC_URL = process.env.PUBLIC_URL

var autoBind = require('react-autobind')

class BookCustomize extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bookMaster: null,
			progression: 0,
			pageWidth: 0,
			pageHeight: 0,
			devicePixelRatio: window.devicePixelRatio,
		}
		autoBind(this)
		this.unityContext = new UnityContext({
			loaderUrl: PUBLIC_URL + 'build/gotech/ehon-web.loader.js',
			dataUrl: PUBLIC_URL + 'build/gotech/ehon-web.data.unityweb',
			frameworkUrl: PUBLIC_URL + 'build/gotech/ehon-web.framework.js.unityweb',
			codeUrl: PUBLIC_URL + 'build/gotech/ehon-web.wasm.unityweb',
		})
		this.unityContext.on('progress', (progression) => {
			this.setProgression(progression)
		})
		this.unityContext.on('Native_JS_OnFinishLoading', () => {
			this.Native_JS_OnFinishLoading()
		})
		this.unityContext.on('Native_JS_OnGoToStart', () => {
			window.open('/add_info_avatar', '_self')
		})
		this.unityContext.on('Native_JS_Save', async (saveData) => {
			const data = JSON.parse(saveData)
			console.log(data, "data")
			const dataImages = data?.imagePdf && data?.imagePdf.length > 0 && data?.imagePdf.map((sticky) => {
				return `data:image/png;base64,${sticky}`
			})
			const images = [...dataImages];
			const stickies = [dataImages[0]];
			for (let index = 0; index < images.length; index++) {
				if ([1, 2, 3, 4, 5, 6, 7, 8].includes(index)) {
					const textImage = require(`../../../Images/stamp_0${index}_text.png`);
					let position = null;
					if ([1, 2].includes(index)) {
						position = { src: images[index], x: 50, y: 90 };
					}
					if ([3].includes(index)) {
						position = { src: images[index], x: -70, y: -140 };
					}
					if ([4].includes(index)) {
						position = { src: images[index], x: 190, y: 70 };
					}
					if ([5].includes(index)) {
						position = { src: images[index], x: -70, y: -140 };
					}
					if ([6].includes(index)) {
						position = { src: images[index], x: -30, y: 80 };
					}
					if ([7, 8].includes(index)) {
						position = { src: images[index], x: -30, y: 150 };
					}
					if ([8].includes(index)) {
						position = { src: images[index], x: -30, y: -150 };
					}
					if ([4].includes(index)) {
						const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
						stickies.push(b64);
					} else {
						const b64 = await mergeImages([position, textImage], { crossOrigin: "Anonymous" })
						stickies.push(b64);
					}
				} else if ([9, 11].includes(index)) {
					const textImage = require(`../../../Images/stamp_0${index}_text.png`);
					let position = null;
					if (index === 9) {
						position = { src: images[index], x: 50, y: 950 };
					} else {
						position = { src: images[index], x: 140, y: 1000 };
					}
					const b64 = await mergeImages([textImage, position], { crossOrigin: "Anonymous" })
					stickies.push(b64);
				} else if ([10].includes(index)) {
					const textImage = require(`../../../Images/stamp_0${index}_text.png`);
					const b64 = await mergeImages(
						[textImage, { src: images[index], x: 0, y: 300 }, require(`../../../Images/stamp_10_pats.png`)],
						{ crossOrigin: "Anonymous" })
					stickies.push(b64);
				}
			}
			const result = this.props?.location?.state?.data;
			const params = new URLSearchParams(window.location.search)
			if (params.get('avatar_id')) {
				await api.saveAvatar({
					customer_id: parseInt(params.get('avatar_id')),
					car_id: data.car,
					eye_id: data.eye,
					mouth_id: data.mouth,
					wheel_id: data.wheel,
					sub_eye_id: data.subEye,
					sub_mouth_id: data.subMouth,
					poses: data.pose,
					bumper: data.bumper,
					body_color: data.bodyColor,
					car_name: data.car_name,
					image: `data:image/png;base64,${data.avatar_image}`,
					eye_color: data.eyeColor,
					wheel_color: data.wheelColor,
					inputCar: data.inputCar,
					mainBodyColor: data._mainBodyColor,
					subBodyColor: data._subBodyColor,
					sub_car_name: data.sub_car_name,
					body_type: data.body_type,
					custom_option: data.custom_option,
					stickies,
				})
				const avatar = await api.getAvatar(parseInt(params.get('avatar_id')))
				if (avatar && avatar.data && avatar.success) {
					const json = {
						storeId: avatar.data.customer?.store_id,
						id: avatar.data.customer.customer_no,
						line_qr: avatar.data.line_qr,
						line_checked: avatar.data.line_checked || false,
						pass: "",
						carData: {
							car: Number(data?.car_id),
							bumper: Number(data?.bumper),
							eye: Number(data?.eye_id),
							subEye: Number(data?.sub_eye_id),
							mouth: Number(data?.mouth_id),
							subMouth: Number(data?.sub_mouth_id),
							wheel: Number(data?.wheel_id),
							bodyColor: Number(data?.body_color),
							pose: Number(data?.poses),
							eyeColor: Number(data?.eye_color),
							wheelColor: Number(data?.wheel_color),
							_mainBodyColor: data?.mainBodyColor,
							_subBodyColor: data?.subBodyColor,
							sub_car_name: data?.sub_car_name,
							inputCar: data?.inputCar,
							body_type: data?.body_type,
							custom_option: data?.custom_option,
						}
					}
					const payload = {
						status: true,
						error: "",
						data: json
					}
					this.unityContext.send('WebBridge', 'JS_Native_DoneCreate', JSON.stringify(payload))
				} else {
					this.unityContext.send('WebBridge', 'JS_Native_DoneCreate', "fail")
				}
			} else {
				const customer = await api.addCustomer({
					name: result.name,
					type: result.type,
					person_in_charge: result.person_in_charge,
				});
				if (customer.success) {
					await api.saveAvatar({
						customer_id: customer.data.id,
						car_id: data.car,
						eye_id: data.eye,
						mouth_id: data.mouth,
						wheel_id: data.wheel,
						sub_eye_id: data.subEye,
						sub_mouth_id: data.subMouth,
						poses: data.pose,
						bumper: data.bumper,
						body_color: data.bodyColor,
						car_name: data.car_name,
						image: `data:image/png;base64,${data.avatar_image}`,
						eye_color: data.eyeColor,
						wheel_color: data.wheelColor,
						inputCar: data.inputCar,
						mainBodyColor: data._mainBodyColor,
						subBodyColor: data._subBodyColor,
						sub_car_name: data.sub_car_name,
						body_type: data.body_type,
						custom_option: data.custom_option,
						stickies,
					})
					const avatar = await api.getAvatar(customer.data.id)
					console.log(avatar, "avt")
					window.history.replaceState(null, null, `?avatar_id=${customer.data.id}`);
					if (avatar && avatar.data && avatar.success) {
						const json = {
							storeId: avatar.data.customer?.store_id,
							id: customer.data.customer_no,
							line_qr: avatar.data.line_qr,
							line_checked: avatar.data.line_checked || false,
							pass: "",
							carData: {
								car: Number(data?.car_id),
								bumper: Number(data?.bumper),
								eye: Number(data?.eye_id),
								subEye: Number(data?.sub_eye_id),
								mouth: Number(data?.mouth_id),
								subMouth: Number(data?.sub_mouth_id),
								wheel: Number(data?.wheel_id),
								bodyColor: Number(data?.body_color),
								pose: Number(data?.poses),
								eyeColor: Number(data?.eye_color),
								wheelColor: Number(data?.wheel_color),
								_mainBodyColor: data?.mainBodyColor,
								_subBodyColor: data?.subBodyColor,
								sub_car_name: data?.sub_car_name,
								inputCar: data?.inputCar,
								body_type: data?.body_type,
								custom_option: data?.custom_option,
							}
						}
						const payload = {
							status: true,
							error: "",
							data: json
						}
						this.unityContext.send('WebBridge', 'JS_Native_DoneCreate', JSON.stringify(payload))
					} else {
						this.unityContext.send('WebBridge', 'JS_Native_DoneCreate', "fail")
					}
				} else {
					this.unityContext.send('WebBridge', 'JS_Native_DoneCreate', "fail")
				}
			}
		})
		this.unityContext.on('Native_JS_Next', (saveData) => {
			// this.Native_JS_Next(saveData)
		})
		this.unityContext.on('Native_JS_SwitchCharacter', () => {
			// this.Native_JS_SwitchCharacter()
		})
		this.unityContext.on('Native_JS_Preview', (previewData) => {
			// this.Native_JS_Preview(previewData)
		})
		this.unityContext.on('Native_JS_Manual', () => {
			// this.Native_JS_Manual()
		})
	}

	async Native_JS_OnFinishLoading() {
		if (!this.state.bookMaster) {
			const params = new URLSearchParams(window.location.search)
			const res = await api.getAvatar(
				params.get('avatar_id')
			)
			if (res && res.data && res.success) {
				const data = res.data;
				const json = {
					storeId: data.customer?.store_id,
					id: data.customer?.customer_no,
					line_qr: data.line_qr,
					line_checked: data.line_checked,
					password: "",
					carData: {
						car: Number(data?.car_id),
						bumper: Number(data?.bumper),
						eye: Number(data?.eye_id),
						subEye: Number(data?.sub_eye_id),
						mouth: Number(data?.mouth_id),
						subMouth: Number(data?.sub_mouth_id),
						wheel: Number(data?.wheel_id),
						bodyColor: Number(data?.body_color),
						pose: Number(data?.poses),
						eyeColor: Number(data?.eye_color),
						wheelColor: Number(data?.wheel_color),
						_mainBodyColor: data?.mainBodyColor,
						_subBodyColor: data?.subBodyColor,
						inputCar: data?.inputCar,
						sub_car_name: data?.sub_car_name,
						body_type: data?.body_type,
						custom_option: data?.custom_option,
					}
				}
				this.unityContext.send('WebBridge', 'JS_Native_UserData', JSON.stringify(json))
			} else {
				this.unityContext.send('WebBridge', 'JS_Native_UserData', JSON.stringify(this.state.bookMaster))
			}
		} else {
			this.unityContext.send('WebBridge', 'JS_Native_UserData', JSON.stringify(this.state.bookMaster))
		}
	}

	componentDidMount() {
		document.title = "アバター作成｜アバターカスタムシステム"
		if (!this.props?.location?.state?.data && !window.location.search.includes('avatar_id')) {
			window.location.replace('/add_info_avatar')
		}
		if (window.location.search.includes('avatar_id')) {
			this.initData()
		}
	}

	setProgression(progression) {
		this.setState({ progression: progression })
	}

	initData = async () => {
		const params = new URLSearchParams(window.location.search)
		const res = await api.getAvatar(
			params.get('avatar_id')
		)
		if (res && res.data && res.success) {
			const data = res.data;
			const json = {
				storeId: data.customer?.store_id,
				id: data.customer?.customer_no,
				line_qr: data.line_qr,
				line_checked: data.line_checked,
				password: "",
				carData: {
					car: Number(data?.car_id),
					bumper: Number(data?.bumper),
					eye: Number(data?.eye_id),
					subEye: Number(data?.sub_eye_id),
					mouth: Number(data?.mouth_id),
					subMouth: Number(data?.sub_mouth_id),
					wheel: Number(data?.wheel_id),
					bodyColor: Number(data?.body_color),
					pose: Number(data?.poses),
					eyeColor: Number(data?.eye_color),
					wheelColor: Number(data?.wheel_color),
					_mainBodyColor: data?.mainBodyColor,
					_subBodyColor: data?.subBodyColor,
					sub_car_name: data?.sub_car_name,
					inputCar: data?.inputCar,
					body_type: data?.body_type,
					custom_option: data?.custom_option,
				}
			}
			this.setState({
				bookMaster: json
			}, () => console.log(this.state.bookMaster))
		}
	}

	render() {
		return (
			<div className='container' style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
				{
					this.unityContext
					&& <Unity
						unityContext={this.unityContext}
						style={this.state.progression === 1 ? { width: '100%', height: '100vh' } : { width: '100%', height: '0vh' }}
						devicePixelRatio={this.devicePixelRatio}
					/>
				}
				<>
					{this.state.progression < 1
						&& <div className='unity-loading-bar'>
							<Row>
								<Col xs='3' />
								<Col xs='6'>
									<Progress striped value={Math.floor(this.state.progression * 100)} max={100} min={0}>{Math.floor(this.state.progression * 100)}%</Progress>
								</Col>
							</Row>
						</div>
					}
				</>
			</div>
		)
	}
}

export default translate('translations')(BookCustomize)
