import { call, put } from 'redux-saga/effects'
import ShopifyActions from '../Redux/ShopifyRedux'
import LoginActions from '../Redux/LoginRedux'
export function * shopifyAddProduct (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res && res.message === 'Unauthenticated.') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.success) {
      yield put(ShopifyActions.shopifyAddProductSuccess(res.data))
    } else {
      yield put(ShopifyActions.shopifyAddProductFailure(res.message))
    }
  } catch (error) {
    yield put(ShopifyActions.shopifyAddProductFailure(error.message))
  }
}

export function * shopifyCreateToken (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res && res.message === 'Unauthenticated.') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.success) {
      yield put(ShopifyActions.createTokenSuccess(res.data))
    } else {
      yield put(ShopifyActions.createTokenFailure(res.message))
    }
  } catch (error) {
    yield put(ShopifyActions.createTokenFailure(error.message))
  }
}
