
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  storeDeleteRequest: ['params'],
  storeDeleteSuccess: ['data'],
  storeDeleteFailure: ['error'],
  clearData: null
})

export const DeleteStoreTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const storeDeleteRequest = state => state.merge({ fetching: true, error: null })
export const storeDeleteSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const storeDeleteFailure = (state, { error }) => state.merge({ fetching: false, error })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.STORE_DELETE_REQUEST]: storeDeleteRequest,
  [Types.STORE_DELETE_SUCCESS]: storeDeleteSuccess,
  [Types.STORE_DELETE_FAILURE]: storeDeleteFailure,
})
