const ApiConfig = {
	baseURL: process.env.REACT_APP_SERVER_API_URL,
	network: process.env.REACT_APP_NETWORK || 'main',
	resourceURL: process.env.REACT_APP_SERVER_RESOURCE_URL,
	shopifyStorefrontToken: process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
	shopifyDomain:
		process.env.REACT_APP_SHOPIFY_DOMAIN || 'dev-ehon.myshopify.com',
}

export default ApiConfig
