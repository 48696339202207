const LOCAL_STORAGE = {
    ROLE: '@@role',
    LIST_QUESTIONS: '@@list_questions',
    STORE_ID: '@@store_id',
    STORE_URL: '@@store_url',
};

export {
    LOCAL_STORAGE
};
