import * as React from 'react';
import { Container, Icon, Message } from './style';

const Alert = ({ error, message, width, background, containerStyle }) => {
    const iconPath = background === '#fff' ? require('../../Containers/assets/icons/error_two.png') : require('../../Containers/assets/icons/error.png');
    return (
        <Container background={background} width={width} style={containerStyle}>
            <Icon style={{marginTop: 4}} src={iconPath} />
            <Message error={error}>{message}</Message>
        </Container>
    );
};

export default Alert;
