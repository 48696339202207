import apisauce from 'apisauce'
import ApiConfig from '../Config/ApiConfig'
import { LOCAL_STORAGE } from '../Utils/constants';
const autoBind = require('react-autobind')
class API {
  constructor(loginToken, baseURL = ApiConfig.baseURL) {
    this.api = apisauce.create({
      // base URL is read from the "constructor"
      baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        // 'Authorization': localStorage.getItem('loginToken') ? 'jwt ' + localStorage.getItem('loginToken') : ''
      },
      // 15 second timeout...
      timeout: 60000
    })
    // const naviMonitor = (response) => // console.log('hey!  listen! ', response)
    // this.api.addMonitor(naviMonitor)

    this.api.axiosInstance.interceptors.request.use(async (config) => {
      const token = await localStorage.getItem('loginToken');
      try {
        if (token) {
          config.headers.Authorization = `jwt ${token}`;
        }
      } catch (e) {
        console.log('Token Problem');
      }
      return config;
    });

    // this.loginToken = loginToken

    autoBind(this)
  }

  authenticated(loginToken) {
    // this.loginToken = loginToken
    localStorage.setItem('loginToken', loginToken)
    this.api.setHeader('Authorization', 'jwt ' + loginToken)
  }

  handleUnauthorizedRequest(rawResponse) {
    if (rawResponse.status === 401) {
      return true
    }
    return false
  }

  preprocessResponse(rawResponse) {
    if (!rawResponse) {
      return { message: 'No response' }
    }

    if (this.handleUnauthorizedRequest(rawResponse)) {
      if (window.location.pathname.includes('admin')) {
        window.location.href = "/admin/login"
      } else {
        window.location.href = "/store/login"
      }
      this.authenticated('')
      return { message: 'Unauthorized' }
    }

    const result = rawResponse.data || {}
    return result
  }

  login(params) {
    return this.api.post('login', params).then(data => {
      const result = data.data
      result.success = result.status === 'success'
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  loginAvatar(params) {
    return this.api.post('auth/avatar/login', params).then(data => {
      const result = data.data
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  loginStore(params) {
    return this.api.post('auth/store/login', params).then(data => {
      const result = data.data
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  adminLogin(params) {
    return this.api.post('auth/login', params).then(data => {
      const result = data.data
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  logout() {
    return this.api.get('logout').then(result => {
      this.authenticated('')
      return result
    })
  }

  adminLogout() {
    return this.api.get('logout').then(result => {
      this.authenticated('')
      return result
    })
  }

  register(params) {
    return this.api.post('register', params).then(data => {
      const result = data.data
      if (!result) {
        return { message: 'No response' }
      }
      result.success = result.status === 'success'
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  forgotPassword(params) {
    return this.api.post('forgot_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  updateUsername(params) {
    return this.api.post('update_username', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  changePassword(params) {
    return this.api.post('change_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  userUpdate(params) {
    return this.api.post('user_update', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  user(params) {
    return this.api.get('me').then(data => {
      return this.preprocessResponse(data)
    })
  }

  adminAuthUser(params) {
    return this.api.get('auth/me').then(data => {
      return this.preprocessResponse(data)
    })
  }

  upload(params) {
    return this.api.post('upload', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  async userList(params) {
    const data = await this.api.get('/admin/user', params)
    return this.preprocessResponse(data)
  }

  async cmsModelList(params) {
    const data = await this.api.get(`/admin/${params.model}`, params)
    return this.preprocessResponse(data)
  }

  async cmsModelUpdate(params) {
    const data = await this.api.put(`/admin/${params.model}/${params.id}`, { data: params.data })
    return this.preprocessResponse(data)
  }

  async cmsModelAdd(params) {
    const data = await this.api.post(`/admin/${params.model}`, { data: params.data })
    return this.preprocessResponse(data)
  }

  async cmsModelDelete(params) {
    const data = await this.api.delete(`/admin/${params.model}`, params)
    return this.preprocessResponse(data)
  }

  async shopifyAddProduct(params) {
    const data = await this.api.post('/shopify/add_product', params)
    return this.preprocessResponse(data)
  }

  async storeList(params) {
    const data = await this.api.get('/admin/store', params)
    return this.preprocessResponse(data)
  }

  async addStore(params) {
    const data = await this.api.post('/admin/store', params)
    return this.preprocessResponse(data)
  }

  async getStoreById(id) {
    const data = await this.api.get(`/admin/store/${id}`)
    return this.preprocessResponse(data)
  }

  async editStore(id, params) {
    const data = await this.api.put(`/admin/store/${id}`, params)
    return this.preprocessResponse(data)
  }

  async deleteStore(id, params) {
    const data = await this.api.delete(`/admin/store/${id}`)
    return this.preprocessResponse(data)
  }

  async avatarList(params) {
    const data = await this.api.get('/store/avatar', params)
    return this.preprocessResponse(data)
  }

  async addCustomer(params) {
    const data = await this.api.post('/store/customer', params)
    return this.preprocessResponse(data)
  }

  async getQuestion() {
    const data = await this.api.get('/store/question')
    return this.preprocessResponse(data)
  }

  async saveAnswer(params) {
    const data = await this.api.post('/store/customer/answer', params)
    return this.preprocessResponse(data)
  }

  async getPdf(id) {
    const data = await this.api.get(`/file/download/pdf/${id}`)
    return data
  }

  async updatePickupDate(id, body) {
    const data = await this.api.put(`/store/avatar/${id}/update_delivery`, body)
    console.log(data)
    return data
  }

  async updateType(id, body) {
    const data = await this.api.put(`/store/avatar/${id}/update_type`, body)
    console.log(data)
    return data
  }

  async updateStatus(id, body) {
    const data = await this.api.put(`/store/avatar/${id}/update_status`, body)
    console.log(data)
    return data
  }

  async getCustomerAnswer(id) {
    const data = await this.api.get(`/store/customer/answer/${id}`)
    return data
  }

  async saveAvatar(body) {
    const data = await this.api.post(`/store/avatar`, body).then((data) => { return this.preprocessResponse(data) })
    return data
  }

  async updateAvatar(id, body) {
    const data = await this.api.put(`/store/avatar/${id}`, body).then((data) => { return this.preprocessResponse(data) })
    return data
  }

  async getAvatar(id) {
    const data = await this.api.get(`/store/avatar/${id}`).then((data) => {
      return this.preprocessResponse(data)
    })
    return data
  }

  async getListStickies(id) {
    const data = await this.api.get(`/store/avatar/${id}/stickies`)
    return data
  }

  async getListStaffByStoreId(params) {
    const data = await this.api.get(`/store/staff`, params)
    return this.preprocessResponse(data)
  }

  async getListOrderHistoryByStoreId(params) {
    const data = await this.api.get('/store/avatar', params)
    return this.preprocessResponse(data)
  }

  async getStaffById(id) {
    const data = await this.api.get(`/store/staff/${id}`)
    return this.preprocessResponse(data)
  }

  async deleteStaff(id) {
    const data = await this.api.delete(`/store/staff/${id}`)
    return this.preprocessResponse(data)
  }

  async addStaff(body) {
    const data = await this.api.post(`/store/staff`, body)
    return this.preprocessResponse(data)
  }

  async editStaff(id, body) {
    const data = await this.api.put(`/store/staff/${id}`, body)
    return this.preprocessResponse(data)
  }

  async deleteAvatar(body) {
    const data = await this.api.delete(`/store/avatar/deleteMany`, null, {
      data: body
    })
    return this.preprocessResponse(data)
  }

  async update_Order_at(body) {
    const data = await this.api.put(`/store/avatar/update_order_at`, null, {
      data: body
    })
    return this.preprocessResponse(data)
  }
  
}




const api = new API()

export default api
