import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect to='/admin/login' />
        )
      }
    />
  )
}

const mapStateToProps = state => ({
  auth: (state.login.data && state.user.data) || (state.adminLogin.data && state.user.data)
    || (state.loginAvatar.data && state.user.data) || (state.loginStore.data && state.user.data)
})

export default connect(mapStateToProps)(PrivateRoute)
