import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import LoginActions from '../../../../Redux/LoginRedux'
import UserRedux from '../../../../Redux/UserRedux'
import LoginInfo from './LoginInfo'
import Logout from './Logout'
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap'
import EllipsisText from 'react-ellipsis-text'
import { createBrowserHistory } from "history";
import { LOCAL_STORAGE } from '../../../../Utils/constants'

const propTypes = {
    children: PropTypes.node
}

const defaultProps = {}

const path = createBrowserHistory().location.pathname
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            role: ''
        }
    }

    logout() {
        const role = localStorage.getItem(LOCAL_STORAGE.ROLE);
        if (role === 'store') {
            window.location.href = '/store-list/login'
        } else {
            window.location.href = '/store/login'
        }
        this.props.logout()
        localStorage.clear()
    }

    componentDidMount() {
        if (this.props.user && this.props.user.locale) {
            this.props.i18n.changeLanguage(this.props.user.locale)
        } else {
            this.props.userRequest()
        }
        const role = localStorage.getItem(LOCAL_STORAGE.ROLE);
        this.setState({
            role
        })

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.fetchingUpate && nextProps.user?.username && this.props.fetchingUpate) {
            window.location.reload()
        }
        if (!nextProps.fetching && nextProps.user.locale && this.props.fetching) {
            this.props.i18n.changeLanguage(nextProps.user.locale)
        }
    }

    render() {
        // eslint-disable-next-line
        const { children, ...attributes } = this.props;
        const langs = {
            en: {
                code: 'en', icon: 'us', name: 'English'
            },
            ja: {
                code: 'ja', icon: 'jp', name: 'Japanese'
            }
        }
        const language = this.props.i18n.language
        const user = this.props.user
        return (
            <>
                <div className='header'>
                    <div className='top-header'>
                        <LoginInfo user={user} />
                        <Nav className='ml-auto mr-3' navbar>
                            <UncontrolledDropdown style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }} direction='down'>
                                {window.location.pathname === '/avatar_management' &&
                                    <>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open('https://manual.ehon-inc.jp/asset/gulliver_manual_01.pdf', '_blank')}>
                                            <strong> <EllipsisText text={'マニュアル'} length={50} /> </strong>
                                        </DropdownToggle>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open(`/staff_management?store_id=${this.props.user?.id}`, '_self')}>
                                            <strong> <EllipsisText text={'スタッフ一覧'} length={50} /> </strong>
                                        </DropdownToggle>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open(`/avatar_management_ehon`, '_self')}>
                                            <strong> <EllipsisText text={'絵本発注一覧'} length={50} /> </strong>
                                        </DropdownToggle>
                                    </>
                                }
                                {window.location.pathname === '/staff_management' &&
                                    <>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open('https://manual.ehon-inc.jp/asset/gulliver_manual_01.pdf', '_blank')}>
                                            <strong> <EllipsisText text={'マニュアル'} length={50} /> </strong>
                                        </DropdownToggle>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open('/avatar_management', '_self')}>
                                            <strong> <EllipsisText text={'ダッシュボード'} length={50} /> </strong>
                                        </DropdownToggle>
                                    </>
                                }
                                {window.location.pathname === '/avatar_management_ehon' &&
                                    <>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open('https://manual.ehon-inc.jp/asset/gulliver_manual_01.pdf', '_blank')}>
                                            <strong> <EllipsisText text={'マニュアル'} length={50} /> </strong>
                                        </DropdownToggle>
                                        <DropdownToggle style={{
                                            marginRight: 20
                                        }} nav onClick={() => window.open('/avatar_management', '_self')}>
                                            <strong> <EllipsisText text={'ダッシュボード'} length={50} /> </strong>
                                        </DropdownToggle>
                                    </>
                                }
                                {<DropdownToggle nav onClick={() => this.logout()}>
                                    <strong> <EllipsisText text={'ログアウト'} length={50} /> </strong>
                                </DropdownToggle>}
                            </UncontrolledDropdown>
                        </Nav>
                    </div>
                </div>
            </>
        )
    }
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        fetchingUpate: state.user.fetchingUpdate,
        fetching: state.user.fetching
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (username, password) => dispatch(LoginActions.logoutRequest()),
        userUpdateRequest: (params) => dispatch(UserRedux.userUpdate(params)),
        userRequest: (params) => dispatch(UserRedux.userRequest(params))
    }
}

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(Header))
