import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 413px;
    position: 'relative';
    margin: 0 auto;
    background: #f4faf8;
    text-align: center;
    margin-top: -31px;
    padding-top: 30px;
    padding-bottom: 100px;
`;

const Section = styled.div`
background: #fff;

`;

const WrapperImageShow = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 22px;
    margin-top: 25px;
`;

const ImageTitle = styled.img`

`;

const ImageHead = styled.img`
width:100%;
`;

const ImageCarAwait = styled.img`
width:100%;
`;

const Image = styled.img`
width:100%;
background-color: white;
`;

const TextTitle = styled.span`
    font-size: 22px;
    color: #008b4f;
    margin-left: 20px;
    font-family: hiragino ud sans rd std;
`;

const TextTitleDescribe = styled.span`
    font-size: 15px;
    color: #008b4f;
    font-family: hiragino ud sans rd std;
    margin-bottom: 30px;
    display: inline-block;
`;

const WrapperImageCar = styled.div`
margin: -30px auto -30px;
width: 250px;
`;

const WrapperImageAwait = styled.div`
    margin-top: 62px;
    
`;

const WrapperImageCarShow = styled.div`
    display: inline-block;
    width: 42%;
    height: 178px;
    margin-right: 9.5px;
    margin-bottom: 19px;
    margin-left: 9.5px;
`;

const WrapperImageCarAwait = styled.div`
    margin-bottom: 19px;
    padding: 0 66px;
`;

const ImageWrapper = styled.div`
    position: 'relative';
    margin: 0 auto;
    background: #f4faf8;
    text-align: center;
    /* padding-top: 30px;
    padding-bottom: 30px; */
`;

const ImageSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: 'relative';
    background: #f4faf8;
    padding: 20px;
`;

const ImageMain = styled.img`
    height: 100%;
    background: #f4faf8;
    @media (min-width: 320px) {
        width: 100%;
        height: auto;
    }
    @media (min-width: 980px) {
        height: 100%;
        width: auto;
        background: #f4faf8;
    }
`;

const ImageContainer = styled.img`
    height: 100%;
    background: #fff;
    @media (min-width: 320px) {
        width: 100%;
        height: auto;
    }
    @media (min-width: 980px) {
        height: 100%;
        width: auto;
        background: #f4faf8;
    }
`;

export {
    ImageMain,
    Section,
    WrapperImageShow,
    Image,
    TextTitle,
    TextTitleDescribe,
    WrapperImageCar,
    WrapperImageAwait,
    WrapperImageCarShow,
    WrapperImageCarAwait,
    ImageTitle,
    ImageHead,
    ImageCarAwait,
    Wrapper,
    ImageSection,
    ImageContainer,
    ImageWrapper,
};