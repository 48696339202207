import React from 'react'
import Loadable from 'react-loadable'
import I18n from '../I18n'

function Loading() {
	return <div>Loading...</div>
}

const Dashboard = Loadable({
	loader: () => import('./views/Dashboard'),
	loading: Loading,
})

const Profile = Loadable({
	loader: () => import('./views/Profile/Profile'),
	loading: Loading,
})

const Users = Loadable({
	loader: () => import('./views/Custom/User/UserList'),
	loading: Loading,
})

const MasterBook = Loadable({
	loader: () => import('./views/Custom/Book/BookMasterList'),
	loading: Loading,
})

const BookPreview = Loadable({
	loader: () => import('./views/Book/BookPreview'),
	loading: Loading,
})

// Shopify
const Products = Loadable({
	loader: () => import('./views/Shopify/Products'),
	loading: Loading,
})

const AddProduct = Loadable({
	loader: () => import('./views/ShopifyAd/Product'),
	loading: Loading,
})

const SurveyQuestion = Loadable({
	loader: () => import('./views/SurveyQuestion'),
	loading: Loading,
})

const AvatarGenerator = Loadable({
	loader: () => import('./views/AvatarGenerator'),
	loading: Loading,
})

const StoreManagement = Loadable({
	loader: () => import('./views/StoreManagement'),
	loading: Loading,
})

const BookInformation = Loadable({
	loader: () => import('./views/BookInformation'),
	loading: Loading,
})

const ConfirmBookInformation = Loadable({
	loader: () => import('./views/ConfirmBookInformation'),
	loading: Loading,
})

const CustormerInformation = Loadable({
	loader: () => import('./views/CustormerInformation'),
	loading: Loading,
})

const PreviewAvatar = Loadable({
	loader: () => import('./views/PreviewAvatar'),
	loading: Loading,
})

const DashboardCustomer = Loadable({
	loader: () => import('./views/DashboardCustomer'),
	loading: Loading,
})

const AvatarManagement = Loadable({
	loader: () => import('./views/AvatarManagement'),
	loading: Loading,
})

const StaffManagement = Loadable({
	loader: () => import('./views/StaffManagement'),
	loading: Loading,
})

const OrderHistory = Loadable({
	loader: () => import('./views/OrderHistory'),
	loading: Loading,
})

const ConfirmAnswer = Loadable({
	loader: () => import('./views/ConfirmAnswer/ConfirmAnswer'),
	loading: Loading,
})

const AddInfoAvatar = Loadable({
	loader: () => import('./views/AddInfoAvatar/AddInfoAvatar'),
	loading: Loading,
})

// const AvatarPage = Loadable({
// 	loader: () => import('./views/AvatarPage/AvatarPage'),
// 	loading: Loading,
// })

const BookCustomize = Loadable({
	loader: () => import('./views/BookCustomize/BookCustomize'),
	loading: Loading,
})

const publicRoutes = [
	{
		path: '/avatar_generator',
		name: I18n.t('tab_avatar_generator'),
		component: AvatarGenerator,
	},
	{
		path: '/book_information',
		name: I18n.t('tab_book_information'),
		component: BookInformation,
	},
	{
		path: '/confirm_book_information',
		name: I18n.t('tab_confirm_book_information'),
		component: ConfirmBookInformation,
	},
	{
		path: '/custormer_information',
		name: I18n.t('tab_custormer_information'),
		component: CustormerInformation,
	},
	{
		path: '/preview_avatar',
		name: I18n.t('tab_preview_avatar'),
		component: PreviewAvatar,
	},
	{
		path: '/dashboard_customer',
		name: I18n.t('tab_dashboard_customer'),
		component: DashboardCustomer,
	},
	{
		path: '/survey_question/:id',
		name: I18n.t('tab_survey_question'),
		component: SurveyQuestion,
	},
	{
		path: '/avatar_management',
		name: I18n.t('tab_avatar_generator'),
		component: AvatarManagement,
	},
	{
		path: '/staff_management',
		name: I18n.t('tab_avatar_generator'),
		component: StaffManagement,
	},
	{
		path: '/avatar_management_ehon',
		name: I18n.t('tab_avatar_generator'),
		component: OrderHistory,
	},
	{
		path: '/confirm_answer/:id',
		name: I18n.t('tab_confirm_answer'),
		component: ConfirmAnswer,
	},
	{
		path: '/add_info_avatar',
		name: I18n.t('tab_add_info_avatar'),
		component: AddInfoAvatar,
	},
]

const routes = [
	{
		path: '/admin/store_management',
		name: I18n.t('tab_avatar_management'),
		component: StoreManagement,
	},

]

export { routes, publicRoutes }
