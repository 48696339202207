
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  shopifyAddProductRequest: ['params'],
  shopifyAddProductSuccess: ['addProductData'],
  shopifyAddProductFailure: ['error'],
  shopifyCreateTokenRequest: ['params'],
  shopifyCreateTokenSuccess: ['data'],
  shopifyCreateTokenFailure: ['error'],
  clearData: null
})

export const ShopifyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  addProductData: null,
  createTokenData: null,
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const shopifyAddProductRequest = state => state.merge({ fetching: true, error: null, addProductData: null, createTokenData: null})
export const shopifyAddProductSuccess = (state, { addProductData }) => state.merge({ fetching: false, error: null, addProductData })
export const shopifyAddProductFailure = (state, { error }) => state.merge({ fetching: false, error, addProductData: null, createTokenData: null})

export const shopifyCreateTokenRequest = state => state.merge({ fetching: true, error: null, addProductData: null, createTokenData: null})
export const shopifyCreateTokenSuccess = (state, { createTokenData }) => state.merge({ fetching: false, error: null, createTokenData })
export const shopifyCreateTokenFailure = (state, { error }) => state.merge({ fetching: false, error, addProductData: null, createTokenData: null})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOPIFY_ADD_PRODUCT_REQUEST]: shopifyAddProductRequest,
  [Types.SHOPIFY_ADD_PRODUCT_SUCCESS]: shopifyAddProductSuccess,
  [Types.SHOPIFY_ADD_PRODUCT_FAILURE]: shopifyAddProductFailure,
  [Types.SHOPIFY_CREATE_TOKEN_REQUEST]: shopifyCreateTokenRequest,
  [Types.SHOPIFY_CREATE_TOKEN_SUCCESS]: shopifyCreateTokenSuccess,
  [Types.SHOPIFY_CREATE_TOKEN_FAILURE]: shopifyCreateTokenFailure
})
