import React from 'react'
import {translate} from 'react-i18next'

const Logout = () => {
  
    return (
      <>
        <div>ログアウト</div>
      </>
    )
  }


export default translate('translations') (Logout)
 