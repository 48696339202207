'use-scrict'

import Loadable from 'react-loadable'
import Loading from './Loading'
import AdminLayout from '../Containers/containers/AdminLayout'
import ForgotPassword from '../Containers/views/Pages/ForgotPassword/ForgotPassword'
import AdminLogin from '../Containers/views/Pages/AdminLogin/AdminLogin'
import CommonLayout from '../Containers/containers/CommonLayout'
import AvatarPage from '../Containers/views/AvatarPage/AvatarPage'
import DetailAvatarPage from '../Containers/views/AvatarPage/Detail'
import LoginAvatar from '../Containers/views/Pages/LoginAvatar/LoginAvatar'
import LoginStoreList from '../Containers/views/Pages/LoginStoreList/LoginStoreList'
import BookCustomize from '../Containers/views/BookCustomize/BookCustomize'

const AsyncNextPage = Loadable({
  loader: () => import('../Routes/NextPage'),
  loading: Loading
})

const routes = [
  {
    title: 'Admin Login',
    path: '/admin/login',
    component: AdminLogin,
    exact: true
  },
  {
    title: 'Avatar Page',
    path: '/avatar_page/:id',
    component: AvatarPage,
    exact: true
  },
  {
    title: 'Avatar Page',
    path: '/avatar_page/:id/:id_main.png',
    component: DetailAvatarPage,
    exact: true
  },
  {
    title: 'Forgot Password',
    path: '/forgot_password',
    component: ForgotPassword
  },
  {
    title: 'Avatar Login',
    path: '/store/login',
    component: LoginAvatar,
    exact: true
  },
  {
    title: 'Store List Login',
    path: '/store-list/login',
    component: LoginStoreList,
    exact: true
  },
  {
    title: 'NextPage',
    path: '/next-page',
    component: AsyncNextPage,
    exact: true
  },
  {
    title: 'Avatar Login',
    path: '/book-customize',
    component: BookCustomize,
    exact: true
  },
  {
    path: '/admin',
    name: 'Home',
    component: AdminLayout
  },
  {
    path: '/',
    name: 'CommonLayout',
    component: CommonLayout
  },
  
]

export default routes
