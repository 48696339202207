import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: inherit;
    outline: 0;
`;

const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
`;

const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    z-index: 100;
    position: relative;
    width: 305px;
    margin: auto;
    min-height: 264px;
    background: #ffffff;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.07),
        0px 0.751293px 3.63125px rgba(0, 0, 0, 0.035);
    border-radius: 2px;
    overflow: auto;
    max-height: calc(100vh - 50px);
`;

const H3 = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    text-align: center;
`;

const WrapButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IMG = styled.img`
    width: 20px;
    height: 20px;
    align-self: flex-end;
    &:hover {
        cursor: pointer;
    }
`;

const WrapButtonFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const H4 = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin: 8px 0px;
`;

const Button = styled.button`
    min-width: 40px;
    max-width: 241px;
    width: 241px;
    height: 41px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    background: #008b4f;
    color: #ffffff;
    border: 0;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 24px;
    &:hover {
        border-color: #008b4f;
    }
    &:focus {
        outline: none;
        border: 2px solid #008b4f;
    }
`;

const SubButton = styled.button`
    min-width: 40px;
    max-width: 241px;
    width: 241px;
    height: 41px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    background: #ffffff;
    color: #008b4f;
    border: 1px solid #008b4f;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 24px;
    &:hover {
        border-color: #008b4f;
    }
    &:focus {
        outline: none;
        border: 2px solid #008b4f;
    }
`;

const Input = styled.input`
    border: 1px solid #ededed;
    border-radius: 2px;
    height: 41px;
    min-width: 40px;
    max-width: 241px;
    width: 241px;
    padding-left: 12px;
    &:hover {
        border-color: #008b4f;
    }
      &:focus {
        outline: none;
        border: 2px solid #008b4f;
    }
`;

const Label = styled.p`
    margin: 0;
    font-size: 14px;
    color: #333333;
    margin-bottom: 9px;
`;

const WrapperInput = styled.div`
    margin-bottom: 24px;
`;

const Select = styled.select`
    border: 1px solid #ededed;
    border-radius: 2px;
    outline: none;
    height: 41px;
    max-width: 241px;
    width: 241px;
    padding-left: 6px;
    font-size: 16px;
    background-color: transparent;
`;


export {
    Wrapper,
    Backdrop,
    StyledModal,
    H3,
    WrapButton,
    IMG,
    WrapButtonFlex,
    H4,
    Button,
    Input,
    Label,
    WrapperInput,
    Select,
    SubButton,
};
